import React, {useRef, useState} from 'react';
import config from '../config';
import {s3Upload} from '../lib/awsLib';
import {onError} from '../lib/errorLib';
import LoaderButton from '../components/LoaderButton';
import AWS from "aws-sdk";



function PdfSplitter() {

  const [isLoading, setIsLoading] = useState(false);
  const [inputKey, setInputKey] = useState(Date.now());
  const fileInputRef = useRef(null);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];

    if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${
          config.MAX_ATTACHMENT_SIZE / 1000000
        } MB.`
      );
      return;
    }

    setIsLoading(true);

    try {
      const fileName = file ? await s3Upload(file) : null;
      await splitPdf(fileName);
      setInputKey(Date.now());
    } catch (e) {
      onError(e);
      setIsLoading(false);
      setInputKey(Date.now());
    }
  };

  async function splitPdf(fileName) {
    // Configure AWS SDK
    AWS.config.update({
      region: 'us-east-1', credentials: {
        accessKeyId: 'AKIASUCVT36ON26TSPFN',
        secretAccessKey: '0M+pMDZBUf8OnY8mz/hg9ko2HkrvwZoJJFn3NxTy'
      }
    }); // Update with your desired AWS region

// Create a Lambda client
    const lambda = new AWS.Lambda();
    const lambdaName = process.env.NODE_ENV === 'development' ? 'splitPdf2' : 'splitPdfProd'

// Define the Lambda function invocation parameters
    const params = {
      FunctionName: lambdaName, // Update with your Lambda function's name
      Payload: JSON.stringify({ fileName: fileName }), // Update with the payload to send to the Lambda function
    };

// Invoke the Lambda function
    lambda.invoke(params, async (err, data) => {
      if (err) {
      } else {
        const bucketParams = {
          Bucket: process.env.REACT_APP_BUCKET,
          Prefix: `${fileName}/`
        };
        let s3 = new AWS.S3({params: bucketParams});
        const testUrl = s3.getSignedUrl('getObject', {Key: JSON.parse(data.Payload).body})
        window.open(testUrl);
        s3 = new AWS.S3({params: {
          Bucket: process.env.REACT_APP_BUCKET
          }})
        await s3.listObjects((err, list)=>{
          if(list.Contents){
            const objectKeys = list.Contents.map(obj => ({ Key: obj.Key }));
            s3.deleteObjects({
              Bucket: process.env.REACT_APP_BUCKET,
              Delete: {
                Objects: objectKeys
              }
            });
          }
        });
      }
      setIsLoading(false);


    });
  }

  return (
    <>
      <div>
        <input type="file" key={inputKey} ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileUpload} />
        <LoaderButton
          size="lg"
          onClick={() => fileInputRef.current.click()}
          variant="primary"
          isLoading={isLoading}
        >Choose Pdf to split</LoaderButton>{' '}
      </div>
    </>
  );
}

export default PdfSplitter;
