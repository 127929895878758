import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./containers/Home";
import NotFound from './containers/NotFound';
import Login from './containers/Login';
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import NewNote from './containers/NewNote';
import Notes from "./containers/Notes";
import Note from './containers/Note';
import PdfSplitter from './containers/PdfSplitter';

export default function Links() {
  return (
    <Routes>
      <Route path="/" element={
        <AuthenticatedRoute>
          <Home />
        </AuthenticatedRoute>
      } />
      <Route path="/pdf-splitter" element={
        <AuthenticatedRoute>
          <PdfSplitter />
        </AuthenticatedRoute>
      } />
      <Route path="/notes" element={
        <AuthenticatedRoute>
          <Note />
        </AuthenticatedRoute>
      } />
      <Route path="/notes/new" element={
        <AuthenticatedRoute>
        <NewNote />
      </AuthenticatedRoute>
      } />
      <Route path="/notes/:id" element={
        <AuthenticatedRoute>
          <Notes />
        </AuthenticatedRoute>
      } />
      <Route
        path="/login"
        element={
          <UnauthenticatedRoute>
            <Login />
          </UnauthenticatedRoute>
        }
      />
      <Route path="*" element={<NotFound />} />;
    </Routes>
  );
}
